// ./frontend/pages/post/[slug].tsx

import client from "../client";

import Image from "next/image";

import imageUrlBuilder from "@sanity/image-url";

import { PortableText } from "@portabletext/react";

import styles from "../styles/page-m01.module.css";

import FullWidthCarousel from "./utils/FullWidthCarousel";

const ReactPlayer = dynamic(() => import("react-player"), { ssr: false });

function urlFor(source: any) {
	return imageUrlBuilder(client).image(source);
}
import dynamic from "next/dynamic";

const consumeParagraph = ({ value }: { value: any }) => {
	if (value?.children?.length === 0) {
		return null;
	}
	return (
		<p className={value?.style}>
			{value?.children.map((item: any, index: number) => {
				let wrappedText = item.text;
				if (item.marks.length > 0) {
					item.marks.reverse().forEach((mark: string, index: number) => {
						const indexMarkDef = value?.markDefs.findIndex(
							(item: { _key: string; _type: string; href: string }) =>
								item._key.includes(mark)
						);
						if (
							indexMarkDef != -1 &&
							value?.markDefs[indexMarkDef]._type === "link"
						) {
							wrappedText = (
								<a
									// style={
									// 	{
									// 		"--link-length": wrappedText.length * 0.0175 + "s",
									// 	} as React.CSSProperties
									// }
									href={value?.markDefs[indexMarkDef].href}
									key={index + item._key}>
									{wrappedText}
								</a>
							);
						} else if (mark === "em") {
							wrappedText = <em key={index + item._key}>{wrappedText}</em>;
						} else if (mark === "strong") {
							wrappedText = (
								<strong key={index + item._key}>{wrappedText}</strong>
							);
						}
					});
				}
				return wrappedText;
			})}
		</p>
	);
};

const ptComponents = {
	types: {
		image: ({ value }: { value: any }) => {
			if (!value?.asset?._ref) {
				return null;
			}
			return (
				<Image
					className={styles.bodyImage}
					alt={value.alt || " "}
					loading="lazy"
					width={1600}
					src={
						urlFor(value)
							.width(1600)
							.fit("max")
							.auto("format") as unknown as string
					}
				/>
			);
		},
		youtube: ({ value }: { value: any }) => {
			// console.log(value);
			if (!value) {
				return null;
			}
			const { url } = value;
			return (
				<ReactPlayer
					className={styles.videoPlayer}
					url={url}
					width={"100%"}
					height={"100%"}
				/>
			);
			// return null;
		},
		gallery: ({ value }: { value: any }) => {
			if (value?.images?.length === 0) {
				return null;
			}
			// console.log("value gallery", value);
			switch (value?.display) {
				case "sideBySide":
					return (
						<div className={`${styles.gallerySideBySide} ${styles.gallery}`}>
							{value?.images.map((image: any, index: number) => {
								{
									return (
										<img
											width={1600}
											key={index}
											alt={image.alt || " "}
											loading="lazy"
											src={
												urlFor(image)
													.width(1600)
													// .fit("max")
													.auto("format") as unknown as string
											}
										/>
									);
								}
							})}
						</div>
					);
					break;
				case "imageGrid":
					return (
						<div className={`${styles.galleryImageGrid} ${styles.gallery}`}>
							{value?.images.map((image: any, index: number) => {
								{
									return (
										<div
											className={styles.imageContainer}
											key={index + "container"}>
											<img
												width={1600}
												key={index}
												alt={image.alt || " "}
												loading="lazy"
												src={
													urlFor(image)
														.width(1600)
														// .fit("max")
														.auto("format") as unknown as string
												}
											/>
										</div>
									);
								}
							})}
						</div>
					);
					break;
				case "fullWidthCarousel":
					return <FullWidthCarousel value={value} />;
					break;
				default:
					return null;
					break;
			}
		},
	},
	block: {
		"text-xs": consumeParagraph,
		"text-s": consumeParagraph,
		"text-lg": consumeParagraph,
		"text-xl": consumeParagraph,
		"text-2xl": consumeParagraph,
		"text-3xl": consumeParagraph,
	},
};

import ErrorPage from "next/error";
import { useRouter } from "next/router";
import { SanityDocument } from "next-sanity";

const Post = ({ post }: { post: SanityDocument }) => {
	// console.log(post);
	return (
		<main>
			<section>
				<div className={styles.sectionContent}>
					<div className={styles.header}>
						<div className={styles.headlineContainer}>
							<h1 className={styles.headline}>{post?.title}</h1>
						</div>
						<div className={styles.heroImageContainer}>
							{post?.heroImage && (
								<img
									className={styles.heroImage}
									src={urlFor(post?.heroImage).width(1600).url()}
									// width={post?.heroImage.width}
									// height={post?.heroImage.height}
									alt="Hero Image"
								/>
							)}
						</div>
						<div className={styles.bodyContent}>
							<PortableText value={post?.body} components={ptComponents} />
							{/* <div className={styles.textA}></div>
							<div className={styles.textB}>
								
							</div> */}
						</div>
					</div>
				</div>
			</section>
		</main>
	);
};

export async function getStaticPaths() {
	const paths = await client.fetch(
		`*[_type == "post" && defined(slug.current)][].slug.current`
	);

	return {
		paths: paths.map((slug: string) => ({ params: { slug } })),
		fallback: true,
	};
}

export async function getStaticProps(context: any) {
	// It's important to default the slug so that it doesn't return "undefined"
	const { slug = "" } = context.params;
	const post = await client.fetch(
		`
    *[_type == "post" && slug.current == $slug][0]
  `,
		{ slug }
	);

	return {
		props: {
			post,
		},
		revalidate: 10,
	};
}

export default Post;
