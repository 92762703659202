"use client";

import styles from "../../styles/page-m01.module.css";

import client from "../../client";
import imageUrlBuilder from "@sanity/image-url";
import { useEffect, useRef, useState } from "react";

function urlFor(source: any) {
	return imageUrlBuilder(client).image(source);
}
function convertRemToPixels(rem: number) {
	return (
		rem *
		parseFloat(window?.getComputedStyle(document.documentElement).fontSize)
	);
}
import { getImageDimensions } from "@sanity/asset-utils";

export default function FullWidthCarousel({ value }: { value: any }) {
	const [remSize, setRemSize] = useState<number>(0);
	const [aspectRatios, setAspectRatios] = useState<number[]>([]);
	const [imageDimensions, setImageDimensions] = useState<DOMRect[]>([]);
	const [widestImage, setWidestImage] = useState<DOMRect>();

	const [galleryContainerRect, setGalleryContainerRect] = useState<DOMRect>();
	const [currentIndex, setCurrentIndex] = useState<number>(0);
	const imageRefs = useRef<(HTMLImageElement | null)[]>([]);
	const galleryContainerRef = useRef<HTMLDivElement | null>(null);

	// Function to record widths of all images
	const updateImageDimensions = () => {
		setRemSize(convertRemToPixels(1.25));
		const dimensions = imageRefs.current.map(
			(img) => img?.getBoundingClientRect() || new DOMRect()
		);
		setImageDimensions(dimensions);
		// console.log(dimensions);
		let widestImageTemp: DOMRect = new DOMRect();
		if (dimensions.length === imageRefs?.current.length) {
			dimensions.forEach((e: DOMRect) => {
				widestImageTemp = widestImageTemp.width > e.width ? widestImageTemp : e;
			});

			setWidestImage(widestImageTemp);
			if (galleryContainerRef.current) {
				// console.log(galleryContainerRef?.current);
				let galleryContainerBoundingClient =
					galleryContainerRef?.current?.getBoundingClientRect();

				setGalleryContainerRect(galleryContainerBoundingClient);
				// if (widestImageTemp?.width > galleryContainerBoundingClient?.width) {
				imageRefs.current.forEach((e: HTMLImageElement | null) => {
					// console.log(
					// 	galleryContainerBoundingClient?.width! *
					// 		(widestImageTemp?.width! / widestImageTemp?.height!),
					// 	galleryContainerBoundingClient?.width!,
					// 	widestImageTemp?.width! / widestImageTemp?.height!
					// );
					e!.style.height =
						galleryContainerBoundingClient?.width! *
							(widestImageTemp?.height! / widestImageTemp?.width!) +
						"px";
				});
				// }
			}
		}
	};

	// Update the widths after the component has rendered
	useEffect(() => {
		updateImageDimensions();
		return () => {};
	}, [imageRefs.current, galleryContainerRef.current]);

	useEffect(() => {
		window.addEventListener("resize", updateImageDimensions);
		return () => {
			window.removeEventListener("resize", updateImageDimensions);
		};
	}, []);

	const nextImage = () => {
		if (currentIndex < imageDimensions.length - 1) {
			setCurrentIndex((prevIndex) => prevIndex + 1);
		} else {
			setCurrentIndex(0); // Loop back to the first image
		}
	};

	// Handle navigation to the previous image
	const prevImage = () => {
		if (currentIndex > 0) {
			setCurrentIndex((prevIndex) => prevIndex - 1);
		} else {
			setCurrentIndex(imageDimensions.length - 1); // Loop back to the last image
		}
	};
	return (
		<div
			ref={galleryContainerRef}
			className={`${styles.galleryFullWidthCarousel} ${styles.gallery}`}>
			<div className={styles.galleryFullWidthCarouselCommandCentre}>
				<button onClick={prevImage}>
					<span className={"text-xl"}>{"<"} Previous</span>
				</button>
				<button onClick={nextImage}>
					<span className={"text-xl"}>Next {">"}</span>
				</button>
			</div>
			<div
				className={styles.fullWidthCarouselContents}
				style={{
					transform: `translateX(${
						-imageDimensions
							?.slice(0, currentIndex)
							.reduce((acc: number, curr: DOMRect) => acc + curr.width, 0) -
						remSize * currentIndex
					}px)`,
				}}>
				<div className={styles.imageContainer}>
					{/* <img
						width={1600}
						ref={(el) => {
							imageRefs.current[0] = el;
						}}
						alt={value?.images[value?.images?.length - 1].alt || " "}
						loading="lazy"
						src={
							urlFor(value?.images[value?.images?.length - 1])
								.width(1600)
								// .fit("max")
								.auto("format") as unknown as string
						}
					/> */}
				</div>
				{value?.images.map((image: any, index: number) => {
					const imageURL = urlFor(image)
						.width(1600)
						// .fit("max")
						.auto("format")
						.url() as unknown as string;
					// console.log(imageURL);
					const { aspectRatio, height, width } = getImageDimensions({
						url: imageURL,
					});

					return (
						<div className={styles.imageContainer} key={index + "container"}>
							<img
								style={{
									height: 768 + "px",
									aspectRatio: aspectRatio,
								}}
								ref={(el) => {
									imageRefs.current[index] = el;
								}}
								width={1600}
								key={index}
								alt={image.alt || " "}
								loading="lazy"
								src={imageURL}
							/>
						</div>
					);
				})}
			</div>
		</div>
	);
}
